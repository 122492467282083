/* Allow nav toggle to gain focus, making it tabbable. */
.nav-toggle {
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.nav-toggle:checked ~ .nav-toggle-label {
  cursor: pointer;

  span {
    transform: translateY(13px) rotate(135deg);
  }

  span::after {
    transform: translateY(-26px) rotate(90deg);
  }

  span::before {
    transform: scale(0);
  }
}

.nav-toggle:focus,
.nav-toggle-label:focus {
  outline: none;
}

.page-front .nav-toggle:checked ~ .nav-toggle-label::before {
  background: {
    color: $color-grey-light;
  }
  transition: background-color 250ms ease-in-out;
}

.page-front .nav-toggle:checked ~ .site-name {
  background-position-x: -650px;
  height: 100px;
  //transition: background-position 250ms ease-in-out;
}

.nav-toggle-label {
  align-items: start;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin: 0 $spacing-horizontal 0 0;
  padding: 0;
  z-index: 100;

  span,
  span::after,
  span::before {
    background: $color-white;
    border-radius: 2px;
    display: block;
    height: 4px;
    position: relative;
    transition: all 250ms ease;
    width: 44px;
  }

  span::after,
  span::before {
    content: '';
    position: absolute;
  }

  span::before {
    top: 13px;
  }

  span::after {
    top: 26px;
  }
}

.page-front {
  .nav-toggle-label {
    margin-top: $spacing-vertical;

    &::before {
      background-color: transparent;
      content: '';
      display: block;
      height: 100px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 250ms ease-in-out 1000ms;
      width: 100vw;
    }
  }

  .site-name {
    //transition: background-position 250ms ease-in-out 750ms;
  }
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  > li > a {
    text-transform: lowercase;
  }

  li {
    list-style: none;
    margin: 0;
  }

  a,
  a:visited {
    color: $color-white;
    text-decoration: none;
  }

  a:active,
  a:hover {
    color: $color-brand-dark;
  }
}

header nav {
  background: $color-grey-light;
  left: 0;
  padding: $spacing-vertical $spacing-horizontal;
  position: absolute;
  text-align: left;
  top: 100px;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 300ms ease-in-out 500ms;
  width: 100%;
  z-index: 60;

  > ul > li {
    border-bottom: 1px solid $color-white;
    padding-bottom: 4px;

    &:last-of-type {
      border-bottom: 0;
    }

    > a {
      display: inline-block;
      font-size: $font-size-m;
      font-weight: $font-weight-bold;
      line-height: $line-height-m;
      padding: 10px;
      white-space: nowrap;
      width: 140px;
    }
  }

  ul {
    padding-left: 10px;

    > li {
      position: relative;
    }

    ul {
      padding-bottom: $spacing-vertical;
    }
  }

  .menu ul li a {
    line-height: $line-height-h2;

    &:hover {
      color: $color-white;
    }
  }
}

header nav a {
  opacity: 0;
  transition: opacity 150ms ease-in-out 500ms;
}

.nav-toggle:checked ~ nav {
  transform: scale(1, 1);
  transition: transform 300ms ease-in-out;
}

.nav-toggle:checked ~ nav a,
header nav > ul > li:hover a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

header {
  @media (min-width: $screen-lg) and (hover: hover) {
    nav {
      // all: unset; /* this causes issues with Edge, since it's unsupported */

      /* the following lines add Edge support */
      background: none;
      left: initial;
      position: relative;
      text-align: left;
      top: initial;
      transition: none;
      transform: scale(1, 1);
      /* end Edge support stuff */

      align-items: start;
      align-self: end;
      display: flex;
      grid-column: 3 / 4;
      justify-content: flex-end;
      padding: $spacing-vertical $spacing-horizontal 0 0;
    }

    nav ul {
      display: flex;
    }

    nav li {
      margin-bottom: 0;
      margin-left: 3em;
    }

    nav > .menu > li > a {
      font-weight: $font-weight-regular;
      opacity: 1;
      padding-bottom: 24px;
      position: relative;
      z-index: 100;

      &.active {
        box-shadow: inset 0 .2em 0 $color-brand-light;
      }

      &:hover {
        color: $color-white;
      }
    }

    nav > ul > li {
      border-bottom: 0;
      line-height: $line-height-base;
      padding-bottom: 0;
    }

    nav .menu > li {
      background-color: transparent;
      position: relative;
      transition: background-color 200ms ease-in-out 300ms;
    }

    nav .menu li ul {
      display: block;
      padding: 0 0 ($spacing-vertical * .5);
      position: absolute;
      transform: scale(1, 0);
      transform-origin: 50% 0;
      transition: transform 200ms ease-in-out 300ms;
      width: 100%;

      &::before {
        background: $color-brand-light;
        content: '';
        display: block;
        height: 50px;
        left: 0;
        position: absolute;
        top: -50px;
        width: 100%;
        z-index: 0;
      }

      li {
        left: 0;
        margin: 0;
        opacity: 0;
        padding: 0 10px;
        transition: opacity 100ms ease-in-out;
        width: 100%;

        a,
        a:visited {
          color: $color-brand-dark;
          display: block;
        }

        a:hover {
          color: $color-white;
        }
      }
    }

    nav .menu > li:hover {
      background-color: $color-brand-light;
      transition: background-color 50ms ease-in-out;
    }

    nav .menu > li:hover ul {
      background: $color-brand-light;
      color: $color-brand-dark;
      position: absolute;
      transform: scale(1, 1) rotate(-.0001deg);
      transition: transform 300ms ease-in-out;

      li {
        opacity: 1;
        transition: opacity 200ms ease-in-out 300ms;
        width: 100%;
      }
    }
  }
}

footer nav {
  font: {
    size: $font-size-h4;
    weight: $font-weight-light;
  }
  line-height: $line-height-h4;

  a,
  a:visited {
    color: $color-white;
    text-decoration: none;
  }

  a:hover,
  .menu a:hover {
    color: $color-brand-light;
  }

  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }

    > li {
      margin-bottom: $spacing-vertical * 2;
      margin-right: $spacing-horizontal;

      @media (max-width: $screen-lg - 1) {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    > li > a {
      font-weight: $font-weight-medium;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  &.menu-disclaimer .menu > li {
    margin-bottom: 0;

    a {
      color: mix($color-brand-light, $color-white);
      font-weight: $font-weight-light;

      &:hover {
        color: $color-brand-light;
      }
    }
  }

  &.menu-disclaimer .menu {
    justify-content: left;
  }
}
