body {
  min-width: 320px;
}

.page-front header {
  height: 612px;
}

@media (min-width: $screen-md) {
  // @TODO: determine need for tablet oriented styling.
}

@media (min-width: $screen-lg) {
  .page-front header {
    height: 660px;

    .site-name {
      //transition: background-position 250ms ease-in-out 750ms;
    }
  }

  h2.hexagon,
  h2.hexagon-light {
    margin: 0 10% $spacing-vertical;
  }

  @media (hover: hover) {
    .nav-toggle-label {
      display: none;
    }
  }
}
