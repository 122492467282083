// Breakpoints.
$screen-mobile-min: 320px;
$screen-tablet-min: 600px;
$screen-desktop-min: 960px;
$screen-highres-min: 1240px;
$screen-sm: $screen-mobile-min;
$screen-md: $screen-tablet-min;
$screen-lg: $screen-desktop-min;
$screen-xl: $screen-highres-min;

// Spacing.
$spacing-horizontal: 20px;
$spacing-vertical: 18px;
$line-size: 2px;

// Fonts.
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap');
$font-roboto: 'Roboto', sans-serif;
$font-brand: $font-roboto;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-size-base: 16px;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 20px;
$font-size-h4: 19px;
$font-size-m: 20px;
$font-size-l: 42px;
$line-height-base: 23px;
$line-height-h1: 48px;
$line-height-h2: 38px;
$line-height-h3: 23px;
$line-height-h4: 28px;
$line-height-m: 30px;
$line-height-l: 56px;

// Colors.
$color-blue: rgba(54,196,225,1);
$color-grey-darker: rgba(20,45,75,1);
$color-grey-dark: rgba(68,81,108,1);
$color-grey: rgba(87,101,133,1);
$color-grey-light: rgba(109,123,152,1);
$color-grey-lighter: rgba(148,157,180,1);
$color-white: #fff;
$color-brand-dark: $color-grey-darker;
$color-brand-light: $color-blue;
$color-overlay-grey: transparentize($color-grey, .5);
$color-overlay-blue: transparentize(mix($color-grey, $color-blue), .5);
