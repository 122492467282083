*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

header {
  background: $color-brand-dark;
  color: $color-white;
  min-height: 100px;
}

.page-front header {
  background: $color-grey-lighter;
}

main {
  section {
    margin: ($spacing-vertical * 2) 0;
    padding: $spacing-vertical $spacing-horizontal;
    z-index: 50;

    &.compact {
      margin: 0;
    }

    &.full {
      margin: 0;
      padding: 0;

      article {
        margin: ($spacing-vertical * 2) 0;
        padding: $spacing-vertical $spacing-horizontal;
      }
    }

    &.inverse {
      background: $color-grey-darker;
      color: $color-white;
    }

    &.inverse-light {
      background: $color-grey;
      color: $color-white;
    }

    &.inverse-lighter {
      background: $color-grey-light;
      color: $color-white;
    }

    .split {
      border-bottom: $line-size solid $color-grey-dark;
      padding-bottom: 2 * $spacing-vertical;
    }

    article + article {
      margin-top: 2 * $spacing-vertical;
    }

    &:last-of-type {
      margin-bottom: 2 * $spacing-vertical;

      &.full {
        margin-bottom: 0;
      }
    }
  }
}

footer {
  background: $color-brand-dark;
  color: $color-white;
  padding: $spacing-vertical 0;

  h2 {
    margin: $spacing-vertical $spacing-horizontal;
  }

  nav {
    margin: $spacing-vertical 0;

    @media (min-width: $screen-md) {
      margin: $spacing-vertical $spacing-horizontal;
    }
  }
}
