img {
  height: auto;
  max-width: 100%;

  &.hexagon {
    @include hexagon-clip;
  }

}

.pattern img.hexagon {
  @supports (filter: grayscale(1)) {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);

    &:focus,
    &:hover {
      filter: grayscale(0);
      -webkit-filter: grayscale(0);
    }
  }
}


.page-news,
.page-news-item {
  .info img {
    max-width: 120px;
  }
}

figure {
  figcaption {
    margin-top: 1.5em;
    padding-top: .5em;
    text-align: center;

    &::after,
    &::before {
      color: $color-brand-light;
      content: ' - ';
      display: inline;
    }
  }
}
