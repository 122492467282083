// @TODO: add fallback code for IE11 and other old browsers not supporting grid.
header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr minmax(220px, 420px) minmax(40px, 670px) 1fr;

  &::after {
    grid-column: 3/5;
    grid-row: 1/3;
  }

  .site-name {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .payoff {
    grid-column: 1/5;
    grid-row: 2/3;

    @media (min-width: $screen-md) {
      grid-column: 2/4;
    }
  }

  nav {
    display: grid;
    grid-column: 1/5;
    grid-row: 1/3;
    grid-template-columns: 1fr minmax(260px, 1090px) 1fr;

    @media (min-width: $screen-lg) and (hover: hover) {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    @media (min-width: $screen-lg) and (not hover: hover) {
      display: grid;
    }

    > .menu {
      grid-column: 2/3;
    }
  }

  .nav-toggle,
  .nav-toggle-label {
    grid-column: 4/5;
    grid-row: 1/2;
  }
}

.page-front header {
  align-items: initial;

  @media (min-width: $screen-lg) {
    grid-template-rows: 100px 460px 100px;

    &::after {
      grid-row: 1/4;
    }

    .site-name {
      display: grid;
      grid-column: 1/3;
      grid-row: 1/3;
      grid-template-columns: 1fr minmax(220px, 415px);

      .logo {
        grid-column: 2/3;
      }
    }

    nav {
      grid-row: 1/2;
    }

    .nav-toggle,
    .nav-toggle-label {
      grid-row: 1/2;
    }

    h2 {
      align-self: center;
      grid-row: 2/3;
    }
  }
}

main {
  display: grid;
  grid-template-columns: 1fr minmax(280px, 1090px) 1fr;

  h2.description {
    grid-column: 1/4;
  }

  p.description {
    align-self: center;
  }

  section {
    grid-column: 2/3;

    &.full {
      display: grid;
      grid-column: 1/4;
      grid-template-columns: 1fr minmax(280px, 1090px) 1fr;

      article {
        grid-column: 2/3;
      }
    }
  }
}

.page-content,
.page-front,
.page-news,
.page-news-item {
  main section article.logo-list {
    align-items: center;
    direction: rtl;
    display: grid;
    grid-gap: $spacing-vertical $spacing-horizontal;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

    img {
      object-fit: contain;
    }
  }

  @media (min-width: $screen-md) {
    main section article {
      display: grid;
      grid-gap: $spacing-vertical 6%;
      grid-template-columns: 47% 47%;

      &.logo-list {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (min-width: $screen-lg) {
    main section article.logo-list {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.page-news,
.page-news-item {
  article .info {
    align-items: start;
    display: grid;
    grid-gap: $spacing-vertical $spacing-horizontal;
    grid-template-columns: 120px auto;
    grid-template-rows: auto 1fr;
    justify-content: left;

    img {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      object-fit: contain;
    }

    h2 {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }

    .author {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }

    @media (min-width: $screen-md) {
      img {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      h2 {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      .author {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
    }
  }
}

footer {
  display: grid;
  grid-gap: $spacing-vertical 0;
  grid-template-columns: 1fr minmax(80px, 218px) repeat(4, minmax(55px, 218px)) 1fr;

  h2 {
    grid-column: 2/7;
  }

  .site-name {
    grid-column: 3/7;

    @media (min-width: $screen-md) {
      grid-column: 2/7;
    }
  }

  nav {
    grid-column: 3/7;

    @media (min-width: $screen-md) {
      &.menu-footer {
        grid-column: 2/7;
      }

      &.menu-disclaimer {
        grid-column: 2/7;
      }
    }

    @media (min-width: $screen-lg) {
      &.menu-footer {
        grid-column: 2/6;
      }

      &.menu-disclaimer {
        grid-column: 6/7;
      }
    }
  }
}
